<template>
  <v-app>
    <b-modal id="message" title="Send message" hide-footer static centered no-close-on-backdrop>
      <div class="row">
        <label class="col-sm-2 col-form-label">{{ $t('reports.to_customer') }}</label>
        <div class="col-sm-10">
          <input v-model="emailTo" type="text" class="form-control" readonly>
        </div>
        <label class="col-sm-2 col-form-label">{{ $t('reports.message') }}</label>
        <div class="col-sm-10 mb-sm-1">
          <textarea v-model="emailText" class="textarea form-control" rows="5" :placeholder="$t('reports.add_message')"></textarea>
        </div>
        <div class="col-sm-12 text-right">
          <div class="send-btn">
            <button type="submit" class="btn btn-primary" v-on:click="sendMessageTo()">{{ $t('reports.send') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
    <v-card>
      <v-card-title>
        <b-button variant="success" @click="getCustomersReports(todayStarts(), todayEnds())">{{ $t('reports.today') }}</b-button>
        <b-button variant="success" class="ml-2" @click="getCustomersReports(yesterdayStarts(), yesterdayEnds())">{{ $t('reports.yesterday') }}</b-button>
        <b-button variant="success" class="ml-2" @click="getCustomersReports(sevenDaysAgoStarts(), yesterdayEnds())">{{ $t('reports.last_7_days') }}</b-button>
        <b-button variant="success" class="ml-2" @click="getCustomersReports(thirtyDaysAgoStarts(), yesterdayEnds())">{{ $t('reports.last_30_days') }}</b-button>
        <b-button variant="success" class="ml-2" @click="getCustomersReports(firstDayOfMonth(), todayEnds())">{{ $t('reports.current_month') }}</b-button>
        <b-button variant="success" class="ml-2" @click="getCustomersReports(firstDayOfPreviousMonth(), lastDayOfPreviousMonth())">{{ $t('reports.previous_month') }}</b-button>
        <flat-pickr @input="changeDate" class="btn ml-2 btn-primary text-white flatpicker-reports" v-model="flatpicker" :config="basicMinTimeConfig" :placeholder="fromDate + ' - ' + toDate"></flat-pickr>
        <v-spacer></v-spacer>
        <div style="width: 100%">
          <v-text-field
            v-model="search"
            append-icon="ri-search-line"
            :label="$t('reports.search')"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="reports"
        :search="search"
        :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
      >
        <template v-slot:foot>
          <br>
          <div class="totalValue">{{ $t('reports.total') }}: {{ totalValue }}</div>
          <br>&nbsp;
        </template>
        <template v-slot:item.bookings="{ item }">
          <b>{{ item.bookingsValue }}</b> <br>{{ item.bookings }} {{ $t('reports.bookings') }}
        </template>
        <template v-slot:item.spendings="{ item }">
          <b>{{ item.spendingsValue }}</b> <br>{{ item.spendings }}
        </template>
        <template v-slot:item.income="{ item }">
          <b>{{ item.incomeValue }}</b> <br>{{ item.income }}
        </template>

      </v-data-table>
    </v-card>
    <download-excel :footer="'Total:' + totalValue" type="csv" :name="$t('2023.costs') + ' ' + fromDate + ' - ' + toDate + '.csv'" class="btn ml-2 btn-success download-excel" :data="jsonReports">
      Export as CSV
    </download-excel>
  </v-app>
</template>

<script>
import { core } from '@/config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import downloadExcel from 'vue-json-excel'

export default {
  components: {
    downloadExcel
  },
  data () {
    return {
      jsonReports: [],
      emailTo: '',
      emailText: '',
      flatpicker: '',
      basicMinTimeConfig: {
        mode: 'range',
        disable: [
          {
            from: new Date(),
            to: new Date('2050-01-01')
          }
        ]
      },
      fromDate: '',
      toDate: '',
      search: '',
      totalValue: '',
      headers: [
        { text: this.$t('2023.booking'), value: 'bookings' },
        { text: this.$t('2023.theSpendings'), value: 'spendings' },
        { text: this.$t('2023.theIncome'), value: 'income' },
        { text: this.$t('2023.total'), value: 'total', align: 'right' }
      ],
      reports: [],
      apiToken: localStorage.getItem('api_token') || ''
    }
  },
  mounted () {
    core.index()
    this.getCustomersReports(this.todayStarts(), this.todayEnds())
  },
  methods: {
    changeDate (ent) {
      if (ent.includes('to')) {
        const theDates = ent.split(' to ')
        const current = new Date(theDates[0])
        const next = new Date(theDates[1])
        this.getCustomersReports(current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate() + ' 00:00:00', next.getFullYear() + '-' + next.getMonth() + '-' + next.getDate() + ' 00:00:00')
      }
    },
    firstDayOfPreviousMonth () {
      const current = new Date()
      current.setMonth(current.getMonth() - 1)
      const date = current.getFullYear() + '-' + current.getMonth() + '-01'
      const time = '00:00:00'
      return date + ' ' + time
    },
    lastDayOfPreviousMonth () {
      const current = new Date()
      current.setDate(0)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    firstDayOfMonth () {
      const current = new Date()
      const date = current.getFullYear() + '-' + current.getMonth() + '-01'
      const time = '00:00:00'
      return date + ' ' + time
    },
    thirtyDaysAgoStarts () {
      const current = new Date()
      current.setDate(current.getDate() - 31)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    sevenDaysAgoStarts () {
      const current = new Date()
      current.setDate(current.getDate() - 8)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    yesterdayStarts () {
      const current = new Date()
      current.setDate(current.getDate() - 1)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    yesterdayEnds () {
      const current = new Date()
      current.setDate(current.getDate() - 1)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '23:59:59'
      return date + ' ' + time
    },
    todayStarts () {
      const current = new Date()
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    todayEnds () {
      const current = new Date()
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds()
      return date + ' ' + time
    },
    getCustomersReports (fromDate, toDate) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const dates = {
        from: fromDate,
        to: toDate
      }
      Axios.post(API.API_COSTS_REPORTS, dates)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.reports = response.data.message.reports
            this.totalValue = response.data.message.totalReportsValue
            this.flatpicker = ''
            this.fromDate = response.data.message.fromDate
            this.toDate = response.data.message.toDate
            this.jsonReports = response.data.message.reportsJson
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    sendMessageToCustomer (email) {
      this.emailTo = email
      this.emailText = ''
      this.$bvModal.show('message')
    },
    sendMessageTo () {
      if (this.emailText === '') {
        core.showSnackbar('error', this.$t('reports.message_not_empty'))
      } else {
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        const theMessage = {
          to: this.emailTo,
          message: this.emailText
        }
        Axios.post(API.API_SEND_MESSAGE, theMessage)
          .then((response) => {
            if (response.data.status === 'return') {
              core.showSnackbar('error', response.data.message)
            } else if (response.data.status === 'success') {
              core.showSnackbar('success', response.data.message)
              this.$bvModal.hide('message')
            } else if (response.data.status === 'upgrade') {
              this.$emit('upgrade_message', response.data.nextPlan)
              this.$bvModal.show('globalUpgrade')
            }
          })
          .catch((error) => {
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    }
  }
}
</script>

<style type="scss">
.flatpicker-reports{
  min-width: 250px;
}
.text-white::-webkit-input-placeholder {
  color: white;
}
.totalValue{
  position: absolute;
  text-align: right;
  width: 100%;
  padding-right: 16px;
  font-weight: bold;
}
.download-excel{
  margin-top: 10px;
  width: 160px;
  display: inline;
}
.send_message{
  cursor: pointer;
}
</style>
